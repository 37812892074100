<template>
  <div :class="flashContainerClass">
    <div
      v-for="({ message, type }, id) in FLASH_DICTIONARY"
      :key="id"
      :class="getFlashClass(type)"
      @click="handleClick(id)"
    >
      <span v-if="isMessageArray(message)">
        <p v-for="(messageItem, index) in message" :key="index">
          {{ messageItem }}
        </p>
      </span>
      <span v-else>{{ message }}</span>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
import {
  Module,
  FLASH_DICTIONARY,
  REMOVE_FLASH,
  ARE_COOKIES_ACCEPTED,
  FLASH,
  EMPTY_STRING,
} from '@/constants'
import { isEmpty, isArray } from '@/helpers'

const CSN_FLASH = 'csn-flash'
const CSN_FLASH_CONTAINER = `${CSN_FLASH}-container`
const CSN_FLASH_CONTAINER_LOWER = `${CSN_FLASH_CONTAINER}-lower`
const CSN_FLASH_CONTAINER_VISIBLE = `${CSN_FLASH_CONTAINER}-visible`
const FLASH_ = 'flash-'

export default {
  name: FLASH,
  computed: {
    ...mapState(Module.FLASH, {
      FLASH_DICTIONARY,
    }),
    ...mapState(Module.MAIN, [ARE_COOKIES_ACCEPTED]),
    hasFlash() {
      return !isEmpty(this.FLASH_DICTIONARY)
    },
    flashContainerClass() {
      return [
        CSN_FLASH_CONTAINER,
        this.ARE_COOKIES_ACCEPTED ? EMPTY_STRING : CSN_FLASH_CONTAINER_LOWER,
        this.hasFlash ? CSN_FLASH_CONTAINER_VISIBLE : EMPTY_STRING,
      ]
    },
  },
  methods: {
    ...mapMutations(Module.FLASH, [REMOVE_FLASH]),
    handleClick(id) {
      this.REMOVE_FLASH({ id })
    },
    getFlashClass(type) {
      return [CSN_FLASH, `${FLASH_}${type}`]
    },
    isMessageArray(message) {
      return isArray(message)
    },
  },
}
</script>
